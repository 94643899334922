export default {
    start: "/core/api/v1/user/start",
    project: "/core/api/v1/user/project",
    rest_login: "/accounts/api/v1/login/",
    rest_logout: "/accounts/api/v1/logout/",
    rest_password_reset: "/accounts/api/v1/password/reset/",
    rest_password_reset_confirm: "/accounts/api/v1/password/reset/confirm/",
    rest_password_change: "/accounts/api/v1/password/change/",
    registration: "/accounts/registration/",
    steam_login: "/accounts/steam/login/?process=login",
    core_mix_projects: "/core/api/v1/router/mix/projects/",
    core_project_users: "/core/api/v1/router/project/users/",
    core_project_find_user: "/core/api/v1/project/find_user",
    core_project_roles: "/core/api/v1/project/roles",
    server_project_session: "/server/api/v1/router/project_session/",
    server_user_online: "/server/api/v1/user/online",
    server_user_social_credit: "/server/api/v1/user/social_credit",
    server_user_karma: "/server/api/v1/user/karma/55555",
    server_user_player: "/server/api/v1/user/player",
    server_user_players: "/server/api/v1/user/players",
    shop_project_deposit: "/shop/api/v1/project_deposit/55555",
    shop_user_info: "/shop/api/v1/user_info/",
    shop_user_products: "/shop/api/v1/user_products/",
    shop_project_products: "/shop/api/v1/router/project_products/",
    shop_user_orders: "/shop/api/v1/router/user_orders/",
    shop_user_wallet: "/shop/api/v1/router/user_wallet/",
    finance_project_operations: "/finance/api/v1/operations",
    finance_user_transactions: "/finance/api/v1/user_transactions",
    statistics_project_sparkline: "/statistics/api/v1/project_sparkline",
    statistics_user_sparkline: "/statistics/api/v1/user_sparkline",
    test_user_info: "/test/api/v1/user_info",
    test_user_verify: "/test/api/ext/v1/verify",
    test_user_test1: "/test/api/ext/v1/router/user_test1/",
}

//