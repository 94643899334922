export default [{
    path: '/shop-user',
    name: 'shop-user',
    meta: {
        title: "Магазин",
        menu: {
            name: "Магазин",
            role: "user.all",
            project:"shop.user",
            index: 10
        }
    },
    component: () => import(/* webpackChunkName: "shop-user" */ '@/site/modules/shop/user/index.vue')
},
    {
        path: '/shop-project',
        name: 'shop-project',
        meta: {
            title: "Магазин",
            menu: {
                name: "Магазин",
                role: "project.admin",
                project:"shop.project",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "shop-project" */ '@/views/shop-project-admin.vue')
    },
]
