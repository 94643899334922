<template>
  <nav>
    <v-app-bar app flat dense hide-on-scroll class="accent"
               style="border-bottom: 1px solid #c3c3c3;">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar class="accent" flat dense>
        <!--    <v-toolbar-title>Title</v-toolbar-title>-->
        <v-toolbar-items>

          <v-row align="center">
            <v-col cols="6">
              <v-select
                  :items="projects"
                  v-model="currProject"
                  @change="projectChange(currProject,'/home')"
                  return-object
                  item-text="name"
                  dense
                  filled
                  hide-details
                  menu-props="auto"
                  disabled
              ></v-select>
            </v-col>

            <v-btn
                class="softvision"
                icon
                color="secondary"
                @click="dialog_projectnew=true"
                disabled
            >
              <!--              <v-icon>mdi-plus</v-icon>-->
              <v-icon>mdi-plus-box-outline</v-icon>
            </v-btn>
          </v-row>

        </v-toolbar-items>

        <v-spacer></v-spacer>

        <v-toolbar-items>

          <v-divider vertical></v-divider>
          <v.shop_balance></v.shop_balance>
          <v-divider vertical></v-divider>

        </v-toolbar-items>
      </v-toolbar>

      <v-menu
          left
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"

          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              @click="$router.push('/home')"
          >
            <v-list-item-title>О проекте</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app class="tertiary" width="220px">
      <v-list>
        <v-list-item>
          <v-list-item-content style="opacity: 0.2">
            <v-list-item-title class="title white--text">
              S.E.W.A
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">
              Space Engeneers Web Admin
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!--        <router-link :to="{name: 'user-profile'}">-->
        <v-list-item class="primary innershadow">

          <v.avatar></v.avatar>

          <v-list-item-content>
            <v-list-item-title class="title z-contur">
              {{ $store.state.user.name }}
            </v-list-item-title>
            <!--            <v-divider dark class="mx-4"></v-divider>-->
            <v-list-item-subtitle class="z-userID">
              {{ $store.state.user.id }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <!--          </div>-->
        </v-list-item>
        <!--        </router-link>-->
      </v-list>


      <v-list dark align="left" style="opacity: 0.3">
        <v-list-group
            v-for="item in menu"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
        >
          <template v-slot:activator>
            <v-divider></v-divider>

            <v-subheader>
              {{ item.title }}
            </v-subheader>
            <!--            <v-list-item-content>!!!!!!-->
            <!--              <v-list-item-title v-text="item.title"></v-list-item-title>-->
            <!--            </v-list-item-content>-->
          </template>

          <div v-for="menu in item.items"
               :key="menu.title"
          >
            <v-list-item v-if="menu.permission.status === 'on'"
                         :to="menu.route"
                         active-class="border"
            >
              <v-list-item-content>
                <v-list-item-title v-text="menu.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!--===========================================-->
            <!--  Если меню отключено для данного проекта  -->
            <v-list-item v-else-if="menu.permission.status === 'off'"
                         style="opacity: 0.2"
            >
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-content
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-list-item-title v-text="menu.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <span>{{ menu.permission.message }}</span>
              </v-tooltip>
            </v-list-item>
            <!--===========================================-->

          </div>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <dialog.projectnew v-model="dialog_projectnew" @closeOK="projectChange"/>

  </nav>
</template>

<script>

const category = {
  "site": {
    "title": "Сайт",
    "action": "mdi-ticket",
    "items": []
  },
  "project": {
    "title": "Проект",
    "action": "mdi-ticket",
    "items": []
  },
  "user": {
    "title": "Меню",
    "action": "mdi-ticket",
    "active": true,
    "items": []
  }
}

export default {
  components: {
    "v.avatar": () => import('@/site/modules/core/user-popup_profile'),
    "v.shop_balance": () => import('@/site/modules/shop/user-balance/balance'),
    "dialog.projectnew": () => import('@/site/modules/core/dialog-new_project'),

  },
  data: () => ({
    dialog_about: false,
    projects: [],
    dialog_projectnew: false,
    drawer: true,
    menu: [],
    currProject: {},
  }),
  created() {
    // console.log('===!navbar!===', this.$route)
    this.projectChange(this.$store.state.project)
  },
  methods: {
    create_menu() {
      /*
      Строка меню отображается только если у пользователя есть на это права
      а также у текущего проекта есть права для доступа к модулю
       */
      this.menu = []
      let new_menu = this.$lib.copy(category) //JSON.parse(JSON.stringify(category))


      this.$router.options.routes.forEach(route => {
        if (route.meta.menu) {

          // Проверка доступа проекта к модулям
          if (route.meta.menu.project)// усли у меню есть ограничения, то смотрим соответствуют ли они текущим параметрам
            if (this.$store.state.project.permission[route.meta.menu.project]) {
              if (this.$store.state.project.permission[route.meta.menu.project].status !== 'hide')
                route.meta.menu.permission = this.$store.state.project.permission[route.meta.menu.project]
              else route.meta.menu.permission = null
            }
          // Проверка доступа пользователя к серверу
          if (this.$store.state.user.permission.includes(route.meta.menu.role)) {
            const lvl = route.meta.menu.role.split(".")
            if (route.meta.menu.permission)
              new_menu[lvl[0]].items.push({
                title: route.meta.menu.name,
                route: route.path,
                permission: route.meta.menu.permission
              })
          }

        }
      })
      // ----
      if (new_menu.site.items.length) this.menu.push(new_menu.site)
      if (new_menu.project.items.length) this.menu.push(new_menu.project)
      if (new_menu.user.items.length) this.menu.push(new_menu.user)
    },
    projectChange(prj, webpage = '') {
      // window.console.log('=== webpage ===', webpage)
      // отправить запрос на смену проекта
      this.$axios.get(this.$url.project, {params: {id: prj.id}})
          .then(response => {
            // получить права для нового проекта
            this.$store.state.user.permission = response.data.user_permission
            this.$store.state.project = response.data.project

            this.projects = this.$store.state.user.projects
            this.currProject = this.projects.find(obj => { return obj.id === this.$store.state.project.id })
            // пересобрать меню пользователя
            this.create_menu()
            // получить доступный url
            // перейти на url
            if (webpage) this.$router.push(webpage)
            this.$bus.emit('reloadPage');
          })
    },
  },
  mounted: function () {
    this.$bus.on("setProject", this.projectChange);
  }
}
</script>

<style>
.v-app-bar {
  /*border-bottom: 1px solid #c3c3c3;*/
}

.innershadow {
  box-shadow: 0px 0px 7px 0px rgb(42 63 84) inset;
}

.z-userID {
  font-size: 0.7rem;
  font-weight: 600;
  opacity: 0.8;
}

.z-contur {
  font-weight: bold;
  color: #181818;
  opacity: 0.7;
  text-shadow: -0 -1px 1px #FFFFFF,
  0 -1px 1px #FFFFFF,
  -0 1px 1px #FFFFFF,
  0 1px 1px #FFFFFF,
  -1px -0 1px #FFFFFF,
  1px -0 1px #FFFFFF,
  -1px 0 1px #FFFFFF,
  1px 0 1px #FFFFFF,
  -1px -1px 1px #FFFFFF,
  1px -1px 1px #FFFFFF,
  -1px 1px 1px #FFFFFF,
  1px 1px 1px #FFFFFF,
  -1px -1px 1px #FFFFFF,
  1px -1px 1px #FFFFFF,
  -1px 1px 1px #FFFFFF,
  1px 1px 1px #FFFFFF;
}
</style>


<i18n>
{
"en": {
"Новый проект": "New project"
}
}
</i18n>


/*
[
{
title: 'Staff',
action: 'mdi-ticket',
active: true,
items: [
{title: 'dashboard', route: '/dashboard'},
{title: 'Page1', route: '/page1'},
{title: 'Page2', route: '/page2'},
{title: 'Page4', route: '/home'},
],
},
{
title: 'Admin',
action: 'mdi-ticket',
items: [
{title: 'dashboard', route: '/dashboard'},
{title: 'Page1', route: '/page1'},
{title: 'Page2', route: '/page2'},
{title: 'hello', route: '/home'},
],
},
{
title: 'User',
action: 'mdi-ticket',
items: [
{title: 'dashboard', route: '/dashboard'},
{title: 'Page1', route: '/page1'},
{title: 'Page2', route: '/page2'},
{title: 'Page4', route: '/home'},
],
},
]
*/