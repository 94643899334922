import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"

import NProgress from 'nprogress'
import 'nprogress/nprogress.css';

NProgress.configure({
    // parent: '#container'
})

Vue.use(VueRouter)


const routes = [
    {
        // http://localhost:8080/autologin/91a32f019c4a4e9d541f4e4bf6fb2d64adebd9d0
        path: '/autologin/:token',
        name: 'autologin',
        meta: {public: true, title: "Авторизация"},
        redirect: to => {
            const {params} = to
            store.state.token = params.token
            localStorage.setItem('access_token', params.token)
            window.location.href = '/home'
            // return '/home'
        }
    },
    {
        path: '/land',
        name: 'site-land',
        meta: {title: "Hello", public:true },
        component: () => import(/* webpackChunkName: "page2" */ '@/views/landing')
    },
    {
        path: '/home',
        name: 'user-home',
        alias: '/',
        meta: {title: "Добро пожаловать"},
        children: [],
        component: () => import(/* webpackChunkName: "home" */ '@/views/core-user-home.vue')
    },
    {
        path: '/home/:project',
        name: 'user-home-name',
        meta: {title: "Добро пожаловать"},
        component: () => import(/* webpackChunkName: "home" */ '@/views/core-user-home.vue')
    },
    {
        path: '/name/:project',
        name: 'goto_project_home',
        meta: {title: "Добро пожаловать"},
        redirect: to => {
            const {params} = to
            console.log('======= param ========', params.project)
            console.log('======= store.state.project ========', store.state)
            let prj = store.state.user.projects.find(obj => { return obj.name_sys === params.project })
            console.log('======= prj ========', prj)
            // window.location.href = '/home'
            // return '/home'
        }
    },

    // {
    //   path: '/public',
    //   name: 'public',
    //   meta: {public: true},
    //   component: () => import(/* webpackChunkName: "public" */ '../views/public.vue')
    // }
]

import shop_router from "@/router/shop-router";
shop_router.forEach(item => routes.push(item));

import core_router from "@/router/core-router";
core_router.forEach(item => routes.push(item));
// let xx = routes.find(obj => { return obj.name === 'user-home' })
// core_router.forEach(item => xx.children.push(item));
// console.log('===')

import accounts_router from "@/views/accounts/router";
accounts_router.forEach(item => routes.push(item));

import finance_router from "@/router/finance-router";
finance_router.forEach(item => routes.push(item));

import statistics_router from "@/router/statistics-router";
statistics_router.forEach(item => routes.push(item));

import mix_router from "@/router/mix-router";
mix_router.forEach(item => routes.push(item));

import test_router from "@/router/test-router";
test_router.forEach(item => routes.push(item));

// import server_router from "@/views/server/router";
// server_router.forEach(item => routes.push( item ));

// window.console.log("!!!!!!!!!!! process",process)

const router = new VueRouter({
    mode: 'history',
    base: '/static/site', // process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    const {title} = to.meta;
    const brand = "SEWA";
    document.title = `${title ? title + " | " : ""}${brand}`;
    // if (to.matched.some(record => record.meta.requiresAuth)) {
    // window.console.log('beforeEach')
    // if ((!to.meta.public) && (!store.getters.loggedIn) && (to.name !== 'home')) {
    if ((!to.meta.public) && (!store.getters.loggedIn)) {
        window.console.log('not loggedIn')
        next({path: '/login',})
        // this.$router.go('/login')
    } else {
        next() // всегда так или иначе нужно вызвать next()!
    }
})

router.beforeResolve((to, from, next) => {
    if (to.name) {
        // Запустить отображение загрузки
        NProgress.start()
    }
    next()
})

router.afterEach(() => {
    // Завершить отображение загрузки
    NProgress.done()
})

export default router

/*
 OBJ -> URL
 router.resolve({ name: 'dashboard' }).href // '/dashboard'

 REDIRECT
 window.location.href = '/home'

<router-link to="/">
    <v-img src="require('@/assets/my-image.png')"></v-img>
</router-link>


 const WithParams = { template: '<div>{{ $route.params.id }}</div>' }
 const routes = [
    { path: '*',
      redirect: to => {
            to
            alert("несуществующий путь! покинуть сайт ?")
        }
    },
    { path: '/a', redirect: { name: 'foo' }},
     { path: '/a', component: A, alias: '/b' },
 { path: 'with-params/:id', component: WithParams },
    { path: '/dynamic-redirect/:id?',
      redirect: to => {
        const { hash, params, query } = to
        if (query.to === 'foo') {
          return { path: '/foo', query: null }
        }
        if (hash === '#baz') {
          return { name: 'baz', hash: '' }
        }
        if (params.id) {
          return '/with-params/:id'
        } else {
          return '/bar'
        }
      }
    },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
 */
//     component: () => import(/* webpackChunkName: "about" */ '../views/user-master.vue')
//   }
// ]