import Vue from 'vue'
import App from './site/App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'
import router from './router'
import i18n from './i18n'
import url from './site/url'
import lib from './site/lib'
import VueBus from './plugins/vue-bus'
import VueYandexMetrika from 'vue-yandex-metrika'
// import {error} from "vue-i18n/src/util";


Vue.use(VueYandexMetrika, {
    id: 55031836,
    router: router,
    env: process.env.NODE_ENV,
    debug: true,
    options: {trackLinks: true, accurateTrackBounce: true, webvisor: true}
})

Vue.use(VueBus)

Vue.prototype.$url = url;
Vue.prototype.$lib = lib;
// Vue.prototype.$lodash = lodash;

// var bus = new Vue({})
Vue.config.productionTip = false

/*
три типа запровсов
1. Authorization+правильный токен => получение user,server данных
1. Authorization+не правильный токен => очистка localStorage и переход на п.3
3. отсутсвует Authorization => переход на login, получение server данных
*/
axios._axios.get(url.start)
    .then(response => {
        /*
        функция сервера возвращает:
        - авторизованным пользователям user и server данные
        - не авторизованным пользователям только  server данные
        если токен кривой, то исключением он удаляется и редирект на login
        */
        store.state.user = response.data.user
        store.state.server = response.data.server
        store.state.project = response.data.project
        window.vue = new Vue({
            vuetify,
            axios,
            router,
            i18n,
            store,
            render: h => h(App)
        }).$mount('#app')
    })
    .catch(error => {
        window.console.log("!Ошибка url.start ", error)
        if (store.state.develop) {
            let aaa = window.confirm(error.message + "\n перейти в /login ?")
            if (!aaa) return
        }
        localStorage.removeItem('access_token')
        store.state.token = null
        router.go('/login')
    })




