// import colors from 'vuetify/lib/util/colors'



export default {
    light: {
        primary: '#29B6F6', // items color
        secondary: '#e0e0e0', // page backgrount
        accent: '#F5F5F5', // toolbar backgrount
        tertiary: {
            base:'#2A3F54',// menu background
            accent1:'#00ff00',
        }
    },
    dark: {
        primary: '#29B6F6', // items color
        secondary: '#e0e0e0', // page backgrount
        accent: '#F5F5F5', // toolbar backgrount
        tertiary: '#2A3F54',// menu background
    },
}

// theme:{
//     primary: '#2A3F54',
//     secondary: '#FF7043',
//     anchor: '#8c9eff',
//     accent: '#FF7043',
//     error: '#FF7043',
//     info: '#FF7043',
//     success: '#FF7043',
//     warning: '#FF7043',
// }