export default [{
        path: '/test-user-master',
        name: 'user-test',
        meta: {
            title: "Тест",
            menu: {
                name: "Тест",
                role: "user.master",
                project:"test.user",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "test-user-master" */ '@/views/test-user-master.vue')
    },
    {
        path: '/test-site-master',
        name: 'site-test',
        meta: {
            title: "Тест",
            menu: {
                name: "Тест",
                role: "site.master",
                project:"test.site",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "test-site-master" */ '@/views/test-site-master.vue')
    },
]
