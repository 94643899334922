export default [{
    path: '/finance-project',
    name: 'finance-project',
    meta: {
        title: "Финансы",
        menu: {
            name: "Финансы",
            role: "project.admin",
            project:"finance.project",
            index: 10
        }
    },
    component: () => import(/* webpackChunkName: "finance-project" */ '@/views/finance-project-operations.vue')
},
    {
        path: '/finance/user-transactions',
        name: 'finance-user-transactions',
        meta: {
            title: "Финансы",
            menu: {
                name: "Финансы",
                role: "user.all",
                project:"finance.user",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "finance-user-transactions" */ '@/views/finance-user-transactions.vue')
    },
]
