export default [
    {
        path: '/mix-user-dashboard',
        name: 'mix-user-dashboard',
        meta: {
            title: "Сводка",
            menu: {
                name: "Сводка",
                role: "user.all",
                project:"server.user",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "mix-user-dashboard" */ '@/views/mix-user-dashboard.vue')
    },
]
