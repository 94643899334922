export default [
    {
        path: '/core-user-profile',
        name: 'core-user-profile',
        meta: {title: "Пофиль пользователя"},
        component: () => import(/* webpackChunkName: "user-profile" */ '@/views/core-user-profile.vue')
    },
    {
        path: '/core-project_owner',
        name: 'core-project_owner',
        meta: {
            title: "Управление проектом",
            menu: {
                name: "Проект",
                role: "project.owner",
                project:"core.project",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "owner-setup" */ '@/views/core-project_owner.vue')
    },
]