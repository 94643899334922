/*
function secondsToString( seconds )
{
    var str = ''

    var numdays = Math.floor(seconds / 86400);

    var numhours = Math.floor((seconds % 86400) / 3600);

    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);

    var numseconds = Math.floor(((seconds % 86400) % 3600) % 60);

    if (numdays) {
        str += numdays + " д "
        if (numhours) str += numhours + " ч "
    }
    else if (numhours){
         str += numhours + " ч "
         if (numminutes) str += numminutes + " мин "
    }
    else if (numminutes){
         str += numminutes + " мин "
         if (numseconds) str += numseconds + " с";
    }
    else str += numseconds + " c"

    return  str
}

function deltaToStr( timestamp ) {
    return secondsToString( (new Date().getTime()/1000 - timestamp) )
}


            Vue.filter('stampTOdate', function (timestamp) {
                //console.log('timestamp', /^\d{5,}d+/.test(timestamp))
                if (!timestamp) return ''
                var date_obj = new Date( timestamp * 1000);
                return date_obj.toLocaleDateString()
            })

            Vue.filter('stampTOtime', function (timestamp) {
                //console.log('timestamp', /^\d{5,}d+/.test(timestamp))
                if (!timestamp) return ''
                var date_obj = new Date( timestamp * 1000 );
                return  date_obj.toLocaleTimeString()
            })

            Vue.filter('withSpace', function (number) {
                if (!number) return ''
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            })


*/

import Vue from 'vue'

Vue.filter('time', function (value) {
    if (!value) return ''

    let d = new Date(Date.parse(value))
    let hours = d.getHours();
    let minutes = d.getMinutes();
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    return hours + ':' + minutes
})

Vue.filter('date', function (value) {
    //console.log('value', parseInt(value))
    if (!value) return ''
    let d = new Date(Date.parse(value))
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    return day + "." + month + "." + year;
})


let deepDiffMapper = function () {
    return {
        VALUE_CREATED: 'created',
        VALUE_UPDATED: 'updated',
        VALUE_DELETED: 'deleted',
        VALUE_UNCHANGED: 'unchanged',
        map: function (obj1, obj2) {
            if (this.isFunction(obj1) || this.isFunction(obj2)) {
                throw 'Invalid argument. Function given, object expected.';
            }
            if (this.isValue(obj1) || this.isValue(obj2)) {
                return {
                    type: this.compareValues(obj1, obj2),
                    data: obj1 === undefined ? obj2 : obj1
                };
            }

            let diff = {};
            for (let key in obj1) {
                if (this.isFunction(obj1[key])) {
                    continue;
                }

                let value2 = undefined;
                if (obj2[key] !== undefined) {
                    value2 = obj2[key];
                }

                diff[key] = this.map(obj1[key], value2);
            }
            for (let key in obj2) {
                if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                    continue;
                }

                diff[key] = this.map(undefined, obj2[key]);
            }

            return diff;

        },
        compareValues: function (value1, value2) {
            if (value1 === value2) {
                return this.VALUE_UNCHANGED;
            }
            if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
                return this.VALUE_UNCHANGED;
            }
            if (value1 === undefined) {
                return this.VALUE_CREATED;
            }
            if (value2 === undefined) {
                return this.VALUE_DELETED;
            }
            return this.VALUE_UPDATED;
        },
        isFunction: function (x) {
            return Object.prototype.toString.call(x) === '[object Function]';
        },
        isArray: function (x) {
            return Object.prototype.toString.call(x) === '[object Array]';
        },
        isDate: function (x) {
            return Object.prototype.toString.call(x) === '[object Date]';
        },
        isObject: function (x) {
            return Object.prototype.toString.call(x) === '[object Object]';
        },
        isValue: function (x) {
            return !this.isObject(x) && !this.isArray(x);
        }
    }
}();


export default {
    formatDate(date, format = "") {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        let yy = date.getFullYear() % 100;
        if (yy < 10) yy = '0' + yy;

        if (format === "day") return dd
        return dd + '.' + mm + '.' + yy;
    },
    update(list, item) {
        /* ДОЛЖЕН БЫТЬ obj.id */
        let index = list.findIndex(obj => obj.id === item.id);
        return Object.assign([], list, {[index]: item})
    },
    restore(target, source) {
        let tmp = JSON.parse(JSON.stringify(source))
        Object.keys(source).forEach(k => target[k] = tmp[k])
    },
    copy(obj) {
    /* normal_data = this.$lib.copy(this.$data) */
        return JSON.parse(JSON.stringify(obj))
        // if (null == obj || "object" != typeof obj) return obj;
        // let copy = obj.constructor();
        // for (let attr in obj) {
            //if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        // if (Object.prototype.hasOwnProperty.call(obj, attr)) copy[attr] = obj[attr];
        // }
        // return copy;
    },
    compare(obj1, obj2) {
        let ob = deepDiffMapper.map(obj1, obj2)
        let res = {}
        Object.keys(ob).forEach(f => {
            if (ob[f].type !== "updated") return
            res[f] = ob[f].data
        })
        return res
    },
    mapping(value, inInterval, outInterval = [1,100]) {
        // this.$lib.mapping( 7000, [-10000, 10000], [1,100])
        let v = ((value - inInterval[0]) * (outInterval[1] - outInterval[0]) / (inInterval[1] - inInterval[0]) + outInterval[0])
        return Math.round(v)
    }

}