export default [
    {
        path: '/statistics-project-dashboard',
        name: 'statistics-project-dashboard',
        meta: {
            title: "Графики",
            menu: {
                name: "Графики",
                role: "project.admin",
                project:"statistics.project",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "statistics-project-sparkline" */ '@/views/statistics-project-sparkline.vue')
    },
]
