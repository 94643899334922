<template>
  <v-app>
    <!--    <template v-if="this.$store.getters.loggedIn">-->
    <template v-if="(this.$route.name !== 'site-land')&&(this.$store.getters.loggedIn)">

      <div class="secondary fill-height">
        <Navbar/>
        <v-main>
          <transition name="fade">
            <v-container fluid>
              <router-view :key="componentKey"/>
            </v-container>
          </transition>
        </v-main>
        <Footer/>
      </div>

    </template>

    <template v-else>
      <v-main>
        <v-container>
          <router-view/>
        </v-container>
      </v-main>
    </template>

    <v-snackbar
        v-model="snackbar_show"
        :timeout="snackbar_timeout"
        multi-line
    >

      <div v-html="snackbar_text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar_show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-app>
</template>

<script>

import Navbar from '@/site/Navbar'
import Footer from '@/site/Footer'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  data: () => ({
    snackbar_show: false,
    snackbar_text: '',
    snackbar_timeout: 3000,
    componentKey: 0,
  }),
  mounted: function () {
    this.$bus.on("snackbar", this.snackbarShow);
    this.$bus.on("reloadPage", this.reloadPage);
    // this.$i18n.locale = 'en'
    // window.console.log("this.$i18n.locale", this.$i18n.locale)
    // window.console.log("this.$i18n.fallbackLocale", this.$i18n.fallbackLocale)
    // this.$i18n.fallbackLocale
  },
  beforeDestroy: function () {
    this.$bus.off("snackbar", this.snackbarShow);
    this.$bus.off("reloadPage", this.reloadPage);
  },
  methods: {
    reloadPage() {
      this.componentKey += 1;
    },
    snackbarShow(params) {

      this.snackbar_timeout = params.timeout ? params.timeout : 3000
      this.snackbar_text = params.text ? params.text : ''

      // if (params.error) {
      //   this.snackbar_text += " " + params.error.message
      // }
      this.snackbar_show = true
    }
  }

};
</script>


<style>

.softvision {
  opacity: 0.6;
}

.softvision:hover {
  opacity: 1;
}
</style>

<style lang="scss">
#app {
  background-image: radial-gradient(circle, #fff 70%, #cccccc);
  //:src="require(`@/assets/${bar.image}`)"
  //$bkg-color: linear-gradient(to bottom, #2193b0, #6dd5ed);
  //background-image: url(require(`@/assets/body_bg.png`);
  //background: url("../assets/body_bg.png") no-repeat center;
}

#nprogress .bar {
  background: #29B6F6 !important;
}

#nprogress .peg {
  /*  box-shadow: 0 0 10px #29B6F6, 0 0 5px #29B6F6; */
}

/* -------------------------------------------- */

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* -------------------------------------------- */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/* W3C standard
   сейчас только для Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888888 #c6c6c6;
}

/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #c6c6c6;
}

*::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 4px;
  border: 2px solid #c6c6c6;
}

// .v-application.theme--light {
//   border:1px solid white;
// }
//.v-application.theme--dark{
//  border:1px solid black;
//}

</style>


/*
PROPS align : 'start','center','end','baseline','stretch'

PRPS justify : 'start','center','end','space-around','space-between'

<!--<v-container fill-height fluid>-->
<!--<v-row align="center"-->
<!--       justify="center">-->
<!--  <v-col></v-col>-->
<!--</v-row>-->
<!--</v-container>-->


================ snackbar =====================
formBalanceOK() {
this.$axios.post(this.$url.shop_project_deposit.replace('55555', this.editedItem.user.usid), {"amount": this.amount})
.then(response => {
response.data
this.$bus.emit('snackbar', {"text": this.$t("Баланс успешно пополнен")});
})
.catch(error => {
this.$bus.emit('snackbar', {"error": error});
return Promise.reject(error);
})
.finally(() => {
this.loading = false;
})
this.form_balance_show = false
}

*/